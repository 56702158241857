<template>

  <el-dialog
      :before-close="toNiuNiu"
      :visible="true"
      title="提示"
      width="500px">
    <span>请填写母牛信息</span>

    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="80px">
      <el-form-item label="编号" prop="cattlrId">
        <el-input v-model="ruleForm.cattlrId" name="cattlrId"></el-input>
      </el-form-item>


      <el-form-item label="照片" prop="img">
        <el-upload
            :before-upload="beforeAvatarUpload"
            :headers=this.token
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            action="http://derder.tech:8080/upload"
            class="avatar-uploader"
            name="image"
        >
          <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="配种日期">
        <el-col :span="24">
          <el-form-item prop="startdate">
            <el-date-picker v-model="ruleForm.startDate" name="startDate" placeholder="选择日期"
                            style="width:100%;" type="date"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="备注" prop="descn">
        <el-input v-model="ruleForm.descn" name="descn" type="textarea"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import service from "@/utils/request";
import store from "@/store"
import router from "@/router";

export default {
  name: "LoginTest",
  data: function () {
    return {
      token: {
        token: store.state.token
      },
      ruleForm: {
        img: '',
        cattlrId: '',
        startDate: '',
        descn: ''
      },
      rules: {
        cattlrId: [
          {required: true, message: '请输入编号', trigger: 'blur'},
          {min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur'}
        ],
        datestart: [
          {type: 'date', required: false, message: '请选择日期', trigger: 'change'}
        ],
        descn: [
          {required: false, message: '备注', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {

    toNiuNiu() {
      router.push('niuniu')
    },

    addMC() {
      service.post("http://derder.tech:8080/niuniu/addMC", JSON.stringify(this.ruleForm), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.data.code) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        } else {
          this.$message.error("添加失败")
        }
      }).catch(() => {
        console.log(this.ruleForm)
        this.$message.error("添加失败")
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          this.addMC();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res) {
      console.log(res)
      console.log(res.data)
      this.ruleForm.img = res.data;
      // console.log(this.ruleForm.img)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  },
  rules: {
    cattlrId: [
      {required: true, message: '请输入编号', trigger: 'blur'},
      {min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur'}
    ],
    startDate: [
      {type: 'date', required: false, message: '请选择日期', trigger: 'change'}
    ],
    descn: [
      {required: false, message: '备注', trigger: 'blur'}
    ]
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
