<template>
  <el-container>
    <el-aside width="300">
      <el-col>
        <el-menu
            active-text-color="#ffd04b"
            background-color="#545c64"
            class="el-menu-vertical-demo"
            default-active="2"
            text-color="#fff"
            @close="handleClose"
            @open="handleOpen">
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>导航一</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">
                <router-link to="/niuniu">展示母牛生产表</router-link>
              </el-menu-item>
              <el-menu-item index="1-2">选项2</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span slot="title">导航二</span>
          </el-menu-item>
          <el-menu-item disabled index="3">
            <i class="el-icon-document"></i>
            <span slot="title">导航三</span>
          </el-menu-item>

        </el-menu>
      </el-col>

    </el-aside>
    <el-container>
      <el-header>Header</el-header>
      <el-main>
        <!--        <niuniu></niuniu>-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>


// import Niuniu from "@/views/niuniu/cattle/Niuniu";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "IndexView",

  created() {
  },
  methods: {
    init() {
      if (localStorage.getItem('token')) {
        let userData = JSON.parse(localStorage.getItem('userData'))
        this.$store.state.token = userData.token
        this.$store.state.userData.userphone = userData.userData.userphone
      }
    },
    goBack() {
      // console.log('go back');
      // this.$router.back();
      this.$router.go(-1)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}

</script>

<style scoped>
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 50px;
}

.el-aside {
  /*background-color: #D3DCE6;*/
  /*color: #333;*/
  text-align: center;

  line-height: 100%;
}


.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 100%;
  padding: 0 0;
}

/deep/ .el-table td,
.el-table th {
  padding: 0;
}
</style>
