<template>
  <div>
    <form>
      <input v-model="loginData.username" type="text"><br>
      <input v-model="loginData.password" type="password"><br>
      <input type="submit" v-on:click="login()">
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TestView",
  data() {
    return {
      loginData: {
        username: '',
        password: ''
      },
      Data: null
    }
  },
  methods: {
    login() {
      axios({
        method: "post",
        url: "http://derder.tech:8080/login",
        data: JSON.parse(JSON.stringify(this.loginData)),
      }).then(res => {
        console.log(this.loginData)
        this.Data = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>
