import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// elementui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// axios
import axios from 'axios';
import VueRouter from "vue-router";

import service from "./utils/request";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);
// Vue.mixin({
//     destroyed() {
//     }
// })
new Vue({
    router,
    store,
    service,
    render: h => h(App)
}).$mount('#app')
