<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="100px" status-icon>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input v-model="ruleForm.pass" autocomplete="off" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input v-model="ruleForm.checkPass" autocomplete="off" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import service from "@/utils/request";
import router from "@/router";

export default {
  name: "RegisterView",
  data() {
    var checkusername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var checkphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号不能为空'));
      } else if (value.length !== 11) {
        callback(new Error('手机号不正确'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        username: '',
        phone: ''
      },
      rules: {
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        username: [
          {validator: checkusername, trigger: 'blur'}
        ],
        phone: [
          {validator: checkphone, trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let user = JSON.stringify({
            "username": this.ruleForm.username,
            "phone": this.ruleForm.phone,
            "password": this.ruleForm.pass,
          })
          console.log(user)
          console.log(JSON.parse(user))
          service.post('http://derder.tech:8080/register', JSON.parse(user)).then(res => {
            if (res.data.code) {
              this.$message({
                message: '注册成功',
                type: 'success'
              })
              router.push('/')
            } else {
              this.$message.error('注册错误');
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(ruleFrom) {
      this.$refs[ruleFrom].resetFields();
    }
  }
}
</script>

<style scoped>


</style>
