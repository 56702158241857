<template>
  <div>
    <!--    <login-view></login-view>-->
    <!--    <index-view></index-view>-->
    <router-view></router-view>
  </div>

</template>

<script>
// import LoginView from "@/views/niuniu/user/LoginView";
// import IndexView from "@/views/niuniu/IndexView";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    // IndexView,
    // eslint-disable-next-line vue/no-unused-components
    // LoginView,
  },
}
</script>


<style>

</style>

