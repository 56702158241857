<template>
  <div>
    手机号：<input v-model="loginData.phone" name="phone" type="text">
    <button v-on:click="getCode">获取验证码</button>
    <br>
    验证码：<input v-model="loginData.code" name="code" type="text"><br>
    <input type="submit" value="登录" @click="codeLogin">
  </div>

</template>


<script>
import service from "@/utils/request";
import store from "@/store";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "forword",
  data() {
    return {
      loginData: {
        phone: '',
        code: ''
      },
    }
  },
  methods: {
    getCode() {
      console.log(7777777777)
      service.post("http://derder.tech:8080/sendCode", JSON.stringify(this.loginData), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log(JSON.stringify(this.loginData.phone))
        if (res.data.code) {
          this.$message({type: 'success', message: "发送成功，请注意查收"})
        } else {
          this.$message.error("手机号格式错误")
        }
      })
    },

    codeLogin() {
      console.log(9999)
      service.post("http://derder.tech:8080/codeLogin", this.loginData, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.data.code) {
          this.$message({
            message: '登录成功',
            type: 'success'
          })
          store.commit('setToken', res.data.data.jwt)
          store.commit('setUserData', res.data.data.uid)
          localStorage.setItem('userData', JSON.stringify(store.state))
          this.$router.push('/niuniu')
        } else {
          this.$message.error("验证码错误")
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
