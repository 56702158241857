import axios from "axios";
import router from "@/router";
//配置中心
// import configs from "@/global.config"
import store from '@/store'


const service = axios.create({
    timeout: 5000,
    responseType: "json",
    baseURL: 'http://derder.tech:8080/'
})

service.interceptors.request.use(
    config => {
        // let whiteList = configs.whiteListApi
        let url = config.url
        let token = store.state.token
        if ("login".indexOf(url) !== -1 || token !== null || "register".indexOf(url) !== -1 || "checkUserId".indexOf(url) !== -1) {
            console.log("*************************")
            config.headers['token'] = token
        } else {
            router.push('/')
        }
        return config
    }, error => {
        console.log(error)
        console.log('请求拦截器报错了？？？？？？',)
        return Promise.reject(error)
    }
)

// service.interceptors.response.use((res) => {
//     let status = res.data.code || 1;
//     let msg = res.data.msg;
//     if (status === 0) {
//         alert(msg)
//     }
//
//
// }, error => {
//     alert(error)
//     return Promise.reject(new Error(error))
// })

export default service;
