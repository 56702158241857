import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/niuniu/user/LoginView";
import RegisterView from "@/views/niuniu/user/RegisterView";
import TestView from "@/views/niuniu/TestView";
import Forword from "@/views/niuniu/user/forword";
import AddMC from "@/views/niuniu/cattle/AddMC";
import IndexView from "@/views/niuniu/IndexView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView,
        meta: {
            title: '登录',
            noneedLogin: true,
        }
    },
    {
        path: '/forword',
        name: 'forword',
        component: Forword
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        meta: {
            title: '注册',
            noneedLogin: true,
        }
    },
    {
        path: '/index',
        name: 'index',
        component: IndexView,
        meta: {
            title: '首页',
            noneedLogin: true,
        }
    },

    {
        path: '/addMC',
        name: 'AddMC',
        component: AddMC
    },

    {
        path: '/test',
        name: 'test',
        component: TestView
    },
    {
        path: '/niuniu',
        name: 'niuniu',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/niuniu/cattle/Niuniu'),
        meta: {
            title: '母牛生产表',
            noneedLogin: false,
        }
    }
]

const router = new VueRouter({
    routes
})

export default router
