import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userData: {
            userid: '',
            username: ''
        },
        muniutableData: [],
        token: ''
    },
    getters: {},
    mutations: {
        // eslint-disable-next-line no-unused-vars
        setToken(state, value) {
            // console.log(state)
            // console.log(value)
            state.token = value
        },
        setUserData(state, value) {
            // console.log(state)
            // console.log(value)
            state.userData.userid = value
        },
    },
    actions: {},
    modules: {}
})
